import "css/cinema-selector.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import * as request from "superagent";

interface ICinemaSelectorState {
  empty: boolean;
  value: string | null;
  elements: Array<CinemaURL>;
}

interface CinemaURL {
  name: string;
  url: string;
}

class CinemaSelector extends React.Component<{}, ICinemaSelectorState> {
  getOptionsFun: any;
  selectCinemaFun: any;

  constructor(props: any) {
    super(props);

    this.state = {
      empty: true,
      value: null,
      elements: []
    };

    request.get("/cinemas").then((resp: any) => {
      let elements = resp.body as Array<CinemaURL>;

      elements.unshift({
        name: "Seleziona un cinema",
        url: null
      });

      this.setState({
        empty: false,
        elements: resp.body as Array<CinemaURL>
      });
    });

    this.getOptionsFun = this.getOptions.bind(this);
    this.selectCinemaFun = this.selectCinema.bind(this);
  }

  getOptions() {
    return this.state.elements.map((e: CinemaURL) => {
      return <option key={e.name} value={e.url}>{e.name}</option>;
    });
  }

  selectCinema(evt: React.SyntheticEvent<any>) {
    if (evt.currentTarget.value !== null) {
      window.location.href = evt.currentTarget.value;
    }
  }

  render() {
    return <div className="text-center">
      <select className="cinema-selector" disabled={this.state.empty} value="" onChange={this.selectCinema}>
        {this.getOptionsFun()}
      </select>
    </div>;
  }
}

ReactDOM.render(
  <CinemaSelector />,
  document.getElementById("cinema-selector")
);
